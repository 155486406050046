@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss/custom.scss';

// //Import Light mode
//@import './assets/scss/style.scss';

//Import Dark RTL mode
// @import './assets/scss/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss/style-rtl.scss';

// Import Dark Mode
@import './assets/scss/style-dark.scss';




.glassC {
  background-color: rgba(150, 150, 150, .25);  
  backdrop-filter: blur(5px);   
  padding-left: 40px;
  border-radius: 10px;
  box-shadow: 10px 10px 42px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 10px 10px 42px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 42px 0px rgba(0,0,0,0.75);
}

.mapboxgl-popup-content{
  background-color: rgba(47, 85, 211, .55);  
  backdrop-filter: blur(35px);   
  padding-left: 40px;
  border-radius: 10px;
  padding: 0px;
}
.sliderr {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2f55d3;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-in {
  animation: slide-in 0.4s forwards;
  -webkit-animation: slide-in 0.4s forwards;
}

.slide-out {
  animation: slide-out 0.4s forwards;
  -webkit-animation: slide-out 0.4s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
.shadow-box-image {
  -webkit-filter: drop-shadow(10px 10px 10px #222);
  filter: drop-shadow(10px 10px 10px #222);
}

.instabg {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
$small: 625px;
$xsmall: 452px;
$xxsmall: 375px;

.switch-lang {
  width: 40px;
  text-align: left;
  cursor: pointer;
  z-index: 50;
  position: absolute;
  top: 20px;
  height: 40px;
  right: 30px;
 
  @media screen and (max-width: $small) {
    top: 90px;
  }
  @media screen and (max-width: $xsmall) {
    top: 160px;
  right: 10px;

  }
  @media screen and (max-width: $xxsmall) {
    top: 20px;
  right: 30px;

  }

}

.switch-lang:hover .lang-dropdown {
  display: block;
}
.switcher-link {
  color: #fff;
}
.switcher-link:hover {
  color: #fff;
}
.current-lang {
  padding: 3px 5px 0px 5px;
  border-radius: 5px;
}
.lang-flag {
  width: 24px;
  display: inline-block;
}
.lang-text {
  display: inline-block;
  margin-left: 5px;
  vertical-align: top;
}
.lang-dropdown {
  display: none;
  background: #34495e;
  border-radius: 5px;
  margin-top: 2px;
}
.selecting-lang {
  padding: 3px 5px 3px 5px;
  cursor: pointer;
}
.selecting-lang:hover {
  background: #22313f;
}
ul > :first-child {
  border-radius: 5px 5px 0px 0px;
}
ul > :last-child {
  border-radius: 0px 0px 5px 5px;
}
li {
  list-style-type: none;
}
.menu-arrow {
  display: inline-block;
  width: 10px;
  margin-left: 10px;
  vertical-align: top;
}
